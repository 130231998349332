import React, {useMemo} from "react";
import {
  BasicTextStyleButton,
  BlockTypeSelect,
  BlockTypeSelectItem,
  blockTypeSelectItems,
  ColorStyleButton,
  CreateLinkButton,
  DefaultReactSuggestionItem,
  FileCaptionButton,
  FileReplaceButton,
  FormattingToolbar,
  FormattingToolbarController,
  getDefaultReactSlashMenuItems,
  NestBlockButton,
  SuggestionMenuController,
  TextAlignButton,
  UnnestBlockButton,
  useCreateBlockNote,
} from "@blocknote/react";
import {BlockNoteView} from "@blocknote/mantine";
import {
  BlockNoteEditor,
  BlockNoteSchema,
  combineByGroup,
  defaultBlockSpecs,
  defaultInlineContentSpecs,
  filterSuggestionItems, PartialBlock,
} from "@blocknote/core";
import {getMentionMenuItems, Mention} from "./mention";
import {EmployeeDto} from "../../data/objects/employee.dto";
import {localeFa} from "../locale.fa";
import {Alert} from "./alert";
import {RiAlertFill} from "react-icons/ri";
import {MdAutoFixHigh} from "react-icons/md";
import {localeEn} from "../locale.en";
import {UploaderService} from "../../share/services/uploader.service";
import {log} from "../../share/utils/logger";
import {
  getMultiColumnSlashMenuItems,
  locales as multiColumnLocales,
  multiColumnDropCursor,
  withMultiColumn,
} from "@blocknote/xl-multi-column";
import {ModalService} from "../../modal/modal.service";
import {SheetDto} from "../../data/objects/Sheet.dto";
import {SheetItemBaseDto} from "../../data/objects/sheet-item-base.dto";
import {AttributeValue, getAttributeMenuItems} from "./attribute";
import {AttributeDto} from "../../data/objects/attribute.dto";
import {schema} from "./schema";
import {addHtmlExportListener} from "./export";
import {getAskAIMenuItem} from "./ai";

export const CustomBlockNoteEditor = (props) => {
  const lang = props.container.lang;
  const id = props.container.id;
  const sheet: SheetDto = props.container.sheet;
  const isTemplate: boolean = props.container.isTemplate;
  const editable: boolean = props.container.editable;
  const initialContent: any = props.container.content;
  const sheetItem: SheetItemBaseDto = props.container.item;
  const uploader: UploaderService = props.container.uploader;
  const modalService: ModalService = props.container.modalService;
  const users: EmployeeDto[] = props.container.users;
  const upload = (file: File) => {
    return uploader.uploadPageFile(file);
  };
  let dictionary = {multi_column: multiColumnLocales.en, ...(lang == "fa" ? localeFa : localeEn)};
  const editor = useCreateBlockNote({
    schema: withMultiColumn(schema),
    dropCursor: multiColumnDropCursor,
    dictionary: dictionary,
    initialContent: initialContent,
    uploadFile: upload,
  });
  let eventTimeout;
  const handleEditorChange = () => {
    const jsonData = editor.document;
    editor.blocksToHTMLLossy(jsonData).then(html => {
      log('html', html);
    });
    if (editable) {
      clearTimeout(eventTimeout);
      eventTimeout = setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("editorChanged", {detail: {content: jsonData,editor:editor, id: id}}),
        );
      }, 3000);
    }
    // });
  };
  addHtmlExportListener(id, editor);

  const slashMenuItems = useMemo(() => {
    return combineByGroup(
      getDefaultReactSlashMenuItems(editor),
      getMultiColumnSlashMenuItems(editor),
    );
  }, [editor]);

  const getAttributeMenuItem = (editor: BlockNoteEditor) => ({
    title: "Sheet Property",
    aliases: ["attribute", "property"],
    group: "Others",
    icon: <MdAutoFixHigh size={18}/>,
    subtext: "",
    onItemClick: () => {
      editor.openSuggestionMenu('&%#');
    },
  });
  const getCustomSlashMenuItems = (editor): DefaultReactSuggestionItem[] => {
    let items = [...slashMenuItems, getAskAIMenuItem(modalService, editor)];
    if (sheet) {
      items.push(getAttributeMenuItem(editor));
    }
    return items;
  };

  return (
    <div className={"h-100 w-100"}>
      <BlockNoteView
        editor={editor}
        editable={editable}
        onChange={handleEditorChange}
        formattingToolbar={false}
        slashMenu={false}
      >
        <FormattingToolbarController
          formattingToolbar={() => (
            <FormattingToolbar>
              <BlockTypeSelect
                key={"blockTypeSelect"}
                items={[
                  ...blockTypeSelectItems(editor.dictionary),
                  {
                    name: "Alert",
                    type: "alert",
                    icon: RiAlertFill,
                    isSelected: (block) => block.type === "alert",
                  } satisfies BlockTypeSelectItem,
                ]}
              />

              <FileCaptionButton key={"fileCaptionButton"}/>
              <FileReplaceButton key={"replaceFileButton"}/>
              <BasicTextStyleButton basicTextStyle={"bold"} key={"boldStyleButton"}/>
              <BasicTextStyleButton basicTextStyle={"italic"} key={"italicStyleButton"}/>
              <BasicTextStyleButton basicTextStyle={"underline"} key={"underlineStyleButton"}/>
              <BasicTextStyleButton basicTextStyle={"strike"} key={"strikeStyleButton"}/>
              <BasicTextStyleButton key={"codeStyleButton"} basicTextStyle={"code"}/>
              <TextAlignButton textAlignment={"left"} key={"textAlignLeftButton"}/>
              <TextAlignButton textAlignment={"center"} key={"textAlignCenterButton"}/>
              <TextAlignButton textAlignment={"right"} key={"textAlignRightButton"}/>
              <ColorStyleButton key={"colorStyleButton"}/>
              <NestBlockButton key={"nestBlockButton"}/>
              <UnnestBlockButton key={"unnestBlockButton"}/>
              <CreateLinkButton key={"createLinkButton"}/>
            </FormattingToolbar>
          )}
        />

        <SuggestionMenuController
          triggerCharacter={"@"}
          getItems={async (query) => filterSuggestionItems(getMentionMenuItems(users, editor), query)}
        />

        <SuggestionMenuController
          triggerCharacter={"&%#"}
          getItems={async (query) => filterSuggestionItems(getAttributeMenuItems(editor, sheet, isTemplate, sheetItem), query)}
        />

        <SuggestionMenuController
          triggerCharacter={"/"}
          getItems={async (query) => filterSuggestionItems(getCustomSlashMenuItems(editor), query)}
        />
      </BlockNoteView>
    </div>
  );
};
