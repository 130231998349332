import {log} from "../../share/utils/logger";

export const addHtmlExportListener = (id,editor)=>{
  window.addEventListener("editorExportHTML" + id, async () => {
    try {
      const html = await editor.blocksToFullHTML(editor.document);
      let styled = extractStyles(html);
      const link = document.createElement("a");
      const objectUrl = URL.createObjectURL(new Blob([styled], { type: "text/html" }));
      link.href = objectUrl;
      link.download = "download.html";
      link.click();
      URL.revokeObjectURL(objectUrl);
    } catch (e) {
      log(e);
    }
  });
}
const extractStyles = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  // Collect all class names used in the HTML
  const classNames = new Set();
  tempDiv.querySelectorAll("[class]").forEach((element) => {
    element.classList.forEach((cls) => classNames.add(cls));
  });

  // Extract styles from all stylesheets
  let styles = "";
  for (const sheet of Array.from(document.styleSheets)) {
    try {
      for (const rule of Array.from(sheet.cssRules)) {
        if (rule instanceof CSSStyleRule) {
          if (
            rule.selectorText &&
            [...classNames].some((cls) => rule.selectorText.includes(`.${cls}`))
          ) {
            styles += rule.cssText + "\n";
          }
        }
      }
    } catch (e) {
      console.warn(`Could not access stylesheet: ${sheet.href}`, e);
    }
  }

  // Embed the styles in a <style> tag in the exported HTML
  const styleTag = `<style>${styles}</style>`;
  const finalHtml = `${styleTag}\n${htmlContent}`;

  return finalHtml;
};


// window.addEventListener("editorExportPdf" + id, async () => {
//   log("exporting");
//   const exporter = new PDFExporter(editor.schema, {
//     styleMapping: pdfDefaultSchemaMappings.styleMapping,
//     inlineContentMapping: {
//       ...pdfDefaultSchemaMappings.inlineContentMapping,
//       mention: (inline, exporter) => {
//         return inline.content;
//       },
//     },
//     blockMapping: {
//       ...pdfDefaultSchemaMappings.blockMapping,
//       column: () => {},
//       columnList: () => {},
//       alert:()=>{}
//     },
//   });
//   const pdfDocument = await exporter.toReactPDFDocument(editor.document);
//   let result = await ReactPDF.renderToStream(pdfDocument);
//   const chunks:any[] = [];
//   for await (const chunk of result) {
//     chunks.push(chunk);
//   }
//   let blob = new Blob(chunks,{type:'application/pdf'});
//   saveAs(blob,'file.pdf');
//
// });
