import {Component, inject, Injector, OnInit} from '@angular/core';
import { fromEvent, Observable } from "rxjs";
import { environment } from "../environments/environment";
import packageInfo from "../../package.json";
import { TranslateService } from '@ngx-translate/core';
import { BasicsRepository } from './data/repository/basics.repository';
import { Messaging } from '@angular/fire/messaging';
import {NotificationService} from "./share/services/notification.service";
import {UrlUtil} from "./share/utils/url.util";
import { ThemeService } from './share/services/theme.service';
import {createCustomElement} from "@angular/elements";
import {AttributeComponent} from "./attributes/attribute/attribute.component";
import {PageAttrValueComponent} from "./attributes/page-attr-value/page-attr-value.component";
import {
  PageAttrTemplateComponent
} from "./attributes/page-attr-template/page-attr-template.component";
import {log} from "./share/utils/logger";
import {DateTime} from "luxon";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {

  title = 'panel';
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  isOnline: boolean = true;
  devMode: boolean = true;
  version = packageInfo.version;


  constructor(private translate: TranslateService,
              private notificationService:NotificationService,
              private urlUtil:UrlUtil,
              private injector:Injector,
              private themeService:ThemeService,
              private baseRepo: BasicsRepository) {
    this.initCustomElements();
    localStorage.setItem('domain', this.urlUtil.getDomain());
  }

  ngOnInit(): void {
    this.devMode = environment.production;
    this.handleAppConnectivityChanges();
    this.themeService.setTheme(this.baseRepo.getTheme());
  }

  initCustomElements(){
    let attrEl = createCustomElement(PageAttrValueComponent,{injector:this.injector});
    let attrTemplateEl = createCustomElement(PageAttrTemplateComponent,{injector:this.injector});
    customElements.define('at-attribute',attrEl);
    customElements.define('at-attr-template',attrTemplateEl);
  }


  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onlineEvent.subscribe(event => {
      this.isOnline = true;
    });

    this.offlineEvent.subscribe(event => {
      this.isOnline = false;
    });
  }
}
