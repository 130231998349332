import {BlockNoteEditor} from "@blocknote/core";
import {MdAutoFixHigh} from "react-icons/md";
import React from "react";

export const getAskAIMenuItem = (modalService,editor: BlockNoteEditor) => ({
  title: "Ask AI",
  aliases: ["ask", "ai"],
  group: "Others",
  icon: <MdAutoFixHigh size={18} />,
  subtext: "",
  onItemClick: async () => {
    let ref = modalService.openAskAiDialog(await editor.blocksToHTMLLossy());
    ref.onClose.subscribe(async (response) => {
      const currentBlock = editor.getTextCursorPosition().block;
      let parsed = await editor.tryParseHTMLToBlocks(response);
      editor.insertBlocks(parsed, currentBlock, "after");
    });
  },
});
