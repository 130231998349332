import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  importProvidersFrom,
  NgModule
} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PanelInterceptor} from './share/services/interceptor/panel-interceptor.service';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ShareModule} from './share/share.module';
import {NgEventBus} from 'ng-event-bus';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {provideLottieOptions} from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_svg';
import {ImportFilesComponent} from './import-files/import-files.component';
import {
  PreviewOfImportFileComponent
} from './import-files/preview-of-import-file/preview-of-import-file.component';
import {getMessaging, provideMessaging} from '@angular/fire/messaging';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../environments/environment';
import * as Sentry from '@sentry/angular';
import {Router} from '@angular/router';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {StepperModule} from 'primeng/stepper';
import {OnboardingModule} from './onboarding/onboarding.module';
import {ImporterModule} from './importer/importer.module';

import {provideLottieServerOptions} from 'ngx-lottie/server';
import {AngularDeviceInformationService} from 'angular-device-information';
import {DividerModule} from "primeng/divider";
import {ServiceWorkerModule} from "@angular/service-worker";
import {GANTT_GLOBAL_CONFIG} from "@worktile/gantt";
import {RedirectComponent} from "./redirect/redirect.component";
import {MessageModule} from "primeng/message";
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";

@NgModule({
  declarations: [AppComponent, ImportFilesComponent, PreviewOfImportFileComponent, RedirectComponent],
  bootstrap: [AppComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    StepperModule,
    ImporterModule,
    OnboardingModule,
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: 'toast-bottom-left',
      progressBar: true,
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
      extend: true,
    }),
    ShareModule,
    DynamicDialogModule,
    DividerModule,
    ToastModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: PanelInterceptor,
      multi: true,
    },
    NgEventBus,
    provideLottieOptions({
      player: () => player,
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    DialogService,
    AngularDeviceInformationService,
    {
      provide: GANTT_GLOBAL_CONFIG,
      useValue: {
        locale: 'en-us',
      }
    },
    MessageService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function playerFactory() {
  return player;
}
